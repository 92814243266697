<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'FlatCounterOperation', title: 'Операції із лічильником' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_counter_operation)"
        :modal="show_counter_operation_dialog"
        @updateItemModal="counterOperationUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Операції по ліч.
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="selectedCounter.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openCounterOperationCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нову операцію по приладу обліку/лічильнику</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onCounterOperationItemClick"
        v-if="selectedCounter.id"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon size="26" class="mr-2" :color="getVerificationType(item.operation_type, 'color')">
          {{ getVerificationType(item.operation_type, 'icon') }}
        </v-icon>
      </template>

      <template v-slot:item.operation_type="{ item }">
        <span>
          {{ getVerificationType(item.operation_type) }}
        </span>
      </template>

      <template v-slot:item.operation_date="{ item }">
        <span>
          {{ item.operation_date | formatDate }}
        </span>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters} from "vuex";
import {getVerificationType} from "@/utils/icons"
export default {
  name: "HWP_FlatCounterOperation",
  components: { MainModal },
  data() {
    return {
      selected_counter_operation: {},
      show_counter_operation_dialog: false,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Операція', value: 'operation_type' },
        { text: 'Дата', value: 'operation_date' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'getFlatCounterOperation',
      flat: 'getFlat',
      selectedCounter: 'getSelectedCounter',
    }),
  },
  methods: {
    getVerificationType,
    counterOperationUpdateItemModal(payload) {
      this.show_counter_operation_dialog = false
      this.selected_counter_operation = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openCounterOperationCreateDialog() {
      this.selected_counter_operation = {
        flat_counter_id: this.selectedCounter.id,
        flat_id: this.flat.id
      }
      this.show_counter_operation_dialog = true
    },
    onCounterOperationItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_counter_id = this.selectedCounter.id
      local_payload.flat_id = this.flat.id

      this.selected_counter_operation = local_payload
      this.show_counter_operation_dialog = true
    },
  },
}
</script>

<style scoped lang="scss">

</style>